import { forwardRef, ReactNode, type ButtonHTMLAttributes, type ForwardedRef } from 'react';
import clsx from 'clsx';
import { Link } from '@tanstack/react-router';
import { CircleNotch } from '@phosphor-icons/react/dist/ssr';

import styles from './button.module.css';

type ButtonProps = {
  isLoading?: boolean;
  variant?: 'primary' | 'outline' | 'danger';
  leftIcon?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  href?: string;
  target?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>) => {
  const {
    children,
    href,
    isLoading,
    size = 'medium',
    target,
    variant = 'primary',
    leftIcon = null,
    disabled = false,
    type = 'button',
    ...rest
  } = props;

  const className = clsx(styles.root, {
    [styles.primary]: variant === 'primary',
    [styles.outline]: variant === 'outline',
    [styles.danger]: variant === 'danger',
    [styles.large]: size === 'large',
    [styles.medium]: size === 'medium',
    [styles.small]: size === 'small',
    [styles.hasLeftIcon]: leftIcon !== null,
    [styles.disabled]: disabled,
  });

  const content = isLoading ? <CircleNotch className={styles.spinner} /> : children;

  return href ? (
    <Link
      to={disabled ? '' : href}
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      className={className}
      target={target}
      preload="intent"
    >
      {leftIcon}
      {content}
    </Link>
  ) : (
    <button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      {...rest}
      disabled={disabled}
      className={className}
      type={type}
    >
      {leftIcon}
      {content}
    </button>
  );
});

Button.displayName = 'Button';

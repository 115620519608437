/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ProtectedImport } from './routes/_protected'
import { Route as GuestImport } from './routes/_guest'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthPasswordResetImport } from './routes/_auth/password-reset'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AuthForgotPasswordImport } from './routes/_auth/forgot-password'
import { Route as ProtectedTendersIndexImport } from './routes/_protected/tenders/index'
import { Route as ProtectedSubcontractorsIndexImport } from './routes/_protected/subcontractors/index'
import { Route as ProtectedDashboardIndexImport } from './routes/_protected/dashboard/index'
import { Route as GuestSubmissionIndexImport } from './routes/_guest/submission/index'
import { Route as GuestSubmissionSubmittedImport } from './routes/_guest/submission/submitted'
import { Route as GuestSubmissionExpiredImport } from './routes/_guest/submission/expired'
import { Route as GuestSubmissionDeclinedImport } from './routes/_guest/submission/declined'
import { Route as GuestSubmissionActionImport } from './routes/_guest/submission/action'
import { Route as GuestSubmissionAcceptedImport } from './routes/_guest/submission/accepted'
import { Route as ProtectedTendersCreateIndexImport } from './routes/_protected/tenders/create/index'
import { Route as ProtectedTendersTenderIdIndexImport } from './routes/_protected/tenders/$tenderId/index'
import { Route as ProtectedSubcontractorsImportIndexImport } from './routes/_protected/subcontractors/import/index'
import { Route as ProtectedSubcontractorsCreateIndexImport } from './routes/_protected/subcontractors/create/index'
import { Route as ProtectedSubcontractorsSubcontractorIdIndexImport } from './routes/_protected/subcontractors/$subcontractorId/index'
import { Route as ProtectedTendersTenderIdPackagesPackageIdIndexImport } from './routes/_protected/tenders/$tenderId/packages/$packageId/index'

// Create/Update Routes

const ProtectedRoute = ProtectedImport.update({
  id: '/_protected',
  getParentRoute: () => rootRoute,
} as any)

const GuestRoute = GuestImport.update({
  id: '/_guest',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthPasswordResetRoute = AuthPasswordResetImport.update({
  id: '/password-reset',
  path: '/password-reset',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => AuthRoute,
} as any)

const ProtectedTendersIndexRoute = ProtectedTendersIndexImport.update({
  id: '/tenders/',
  path: '/tenders/',
  getParentRoute: () => ProtectedRoute,
} as any)

const ProtectedSubcontractorsIndexRoute =
  ProtectedSubcontractorsIndexImport.update({
    id: '/subcontractors/',
    path: '/subcontractors/',
    getParentRoute: () => ProtectedRoute,
  } as any)

const ProtectedDashboardIndexRoute = ProtectedDashboardIndexImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => ProtectedRoute,
} as any)

const GuestSubmissionIndexRoute = GuestSubmissionIndexImport.update({
  id: '/submission/',
  path: '/submission/',
  getParentRoute: () => GuestRoute,
} as any)

const GuestSubmissionSubmittedRoute = GuestSubmissionSubmittedImport.update({
  id: '/submission/submitted',
  path: '/submission/submitted',
  getParentRoute: () => GuestRoute,
} as any)

const GuestSubmissionExpiredRoute = GuestSubmissionExpiredImport.update({
  id: '/submission/expired',
  path: '/submission/expired',
  getParentRoute: () => GuestRoute,
} as any)

const GuestSubmissionDeclinedRoute = GuestSubmissionDeclinedImport.update({
  id: '/submission/declined',
  path: '/submission/declined',
  getParentRoute: () => GuestRoute,
} as any)

const GuestSubmissionActionRoute = GuestSubmissionActionImport.update({
  id: '/submission/action',
  path: '/submission/action',
  getParentRoute: () => GuestRoute,
} as any)

const GuestSubmissionAcceptedRoute = GuestSubmissionAcceptedImport.update({
  id: '/submission/accepted',
  path: '/submission/accepted',
  getParentRoute: () => GuestRoute,
} as any)

const ProtectedTendersCreateIndexRoute =
  ProtectedTendersCreateIndexImport.update({
    id: '/tenders/create/',
    path: '/tenders/create/',
    getParentRoute: () => ProtectedRoute,
  } as any)

const ProtectedTendersTenderIdIndexRoute =
  ProtectedTendersTenderIdIndexImport.update({
    id: '/tenders/$tenderId/',
    path: '/tenders/$tenderId/',
    getParentRoute: () => ProtectedRoute,
  } as any)

const ProtectedSubcontractorsImportIndexRoute =
  ProtectedSubcontractorsImportIndexImport.update({
    id: '/subcontractors/import/',
    path: '/subcontractors/import/',
    getParentRoute: () => ProtectedRoute,
  } as any)

const ProtectedSubcontractorsCreateIndexRoute =
  ProtectedSubcontractorsCreateIndexImport.update({
    id: '/subcontractors/create/',
    path: '/subcontractors/create/',
    getParentRoute: () => ProtectedRoute,
  } as any)

const ProtectedSubcontractorsSubcontractorIdIndexRoute =
  ProtectedSubcontractorsSubcontractorIdIndexImport.update({
    id: '/subcontractors/$subcontractorId/',
    path: '/subcontractors/$subcontractorId/',
    getParentRoute: () => ProtectedRoute,
  } as any)

const ProtectedTendersTenderIdPackagesPackageIdIndexRoute =
  ProtectedTendersTenderIdPackagesPackageIdIndexImport.update({
    id: '/tenders/$tenderId/packages/$packageId/',
    path: '/tenders/$tenderId/packages/$packageId/',
    getParentRoute: () => ProtectedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_guest': {
      id: '/_guest'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof GuestImport
      parentRoute: typeof rootRoute
    }
    '/_protected': {
      id: '/_protected'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof ProtectedImport
      parentRoute: typeof rootRoute
    }
    '/_auth/forgot-password': {
      id: '/_auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/password-reset': {
      id: '/_auth/password-reset'
      path: '/password-reset'
      fullPath: '/password-reset'
      preLoaderRoute: typeof AuthPasswordResetImport
      parentRoute: typeof AuthImport
    }
    '/_guest/submission/accepted': {
      id: '/_guest/submission/accepted'
      path: '/submission/accepted'
      fullPath: '/submission/accepted'
      preLoaderRoute: typeof GuestSubmissionAcceptedImport
      parentRoute: typeof GuestImport
    }
    '/_guest/submission/action': {
      id: '/_guest/submission/action'
      path: '/submission/action'
      fullPath: '/submission/action'
      preLoaderRoute: typeof GuestSubmissionActionImport
      parentRoute: typeof GuestImport
    }
    '/_guest/submission/declined': {
      id: '/_guest/submission/declined'
      path: '/submission/declined'
      fullPath: '/submission/declined'
      preLoaderRoute: typeof GuestSubmissionDeclinedImport
      parentRoute: typeof GuestImport
    }
    '/_guest/submission/expired': {
      id: '/_guest/submission/expired'
      path: '/submission/expired'
      fullPath: '/submission/expired'
      preLoaderRoute: typeof GuestSubmissionExpiredImport
      parentRoute: typeof GuestImport
    }
    '/_guest/submission/submitted': {
      id: '/_guest/submission/submitted'
      path: '/submission/submitted'
      fullPath: '/submission/submitted'
      preLoaderRoute: typeof GuestSubmissionSubmittedImport
      parentRoute: typeof GuestImport
    }
    '/_guest/submission/': {
      id: '/_guest/submission/'
      path: '/submission'
      fullPath: '/submission'
      preLoaderRoute: typeof GuestSubmissionIndexImport
      parentRoute: typeof GuestImport
    }
    '/_protected/dashboard/': {
      id: '/_protected/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof ProtectedDashboardIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/subcontractors/': {
      id: '/_protected/subcontractors/'
      path: '/subcontractors'
      fullPath: '/subcontractors'
      preLoaderRoute: typeof ProtectedSubcontractorsIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/tenders/': {
      id: '/_protected/tenders/'
      path: '/tenders'
      fullPath: '/tenders'
      preLoaderRoute: typeof ProtectedTendersIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/subcontractors/$subcontractorId/': {
      id: '/_protected/subcontractors/$subcontractorId/'
      path: '/subcontractors/$subcontractorId'
      fullPath: '/subcontractors/$subcontractorId'
      preLoaderRoute: typeof ProtectedSubcontractorsSubcontractorIdIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/subcontractors/create/': {
      id: '/_protected/subcontractors/create/'
      path: '/subcontractors/create'
      fullPath: '/subcontractors/create'
      preLoaderRoute: typeof ProtectedSubcontractorsCreateIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/subcontractors/import/': {
      id: '/_protected/subcontractors/import/'
      path: '/subcontractors/import'
      fullPath: '/subcontractors/import'
      preLoaderRoute: typeof ProtectedSubcontractorsImportIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/tenders/$tenderId/': {
      id: '/_protected/tenders/$tenderId/'
      path: '/tenders/$tenderId'
      fullPath: '/tenders/$tenderId'
      preLoaderRoute: typeof ProtectedTendersTenderIdIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/tenders/create/': {
      id: '/_protected/tenders/create/'
      path: '/tenders/create'
      fullPath: '/tenders/create'
      preLoaderRoute: typeof ProtectedTendersCreateIndexImport
      parentRoute: typeof ProtectedImport
    }
    '/_protected/tenders/$tenderId/packages/$packageId/': {
      id: '/_protected/tenders/$tenderId/packages/$packageId/'
      path: '/tenders/$tenderId/packages/$packageId'
      fullPath: '/tenders/$tenderId/packages/$packageId'
      preLoaderRoute: typeof ProtectedTendersTenderIdPackagesPackageIdIndexImport
      parentRoute: typeof ProtectedImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthPasswordResetRoute: typeof AuthPasswordResetRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthPasswordResetRoute: AuthPasswordResetRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface GuestRouteChildren {
  GuestSubmissionAcceptedRoute: typeof GuestSubmissionAcceptedRoute
  GuestSubmissionActionRoute: typeof GuestSubmissionActionRoute
  GuestSubmissionDeclinedRoute: typeof GuestSubmissionDeclinedRoute
  GuestSubmissionExpiredRoute: typeof GuestSubmissionExpiredRoute
  GuestSubmissionSubmittedRoute: typeof GuestSubmissionSubmittedRoute
  GuestSubmissionIndexRoute: typeof GuestSubmissionIndexRoute
}

const GuestRouteChildren: GuestRouteChildren = {
  GuestSubmissionAcceptedRoute: GuestSubmissionAcceptedRoute,
  GuestSubmissionActionRoute: GuestSubmissionActionRoute,
  GuestSubmissionDeclinedRoute: GuestSubmissionDeclinedRoute,
  GuestSubmissionExpiredRoute: GuestSubmissionExpiredRoute,
  GuestSubmissionSubmittedRoute: GuestSubmissionSubmittedRoute,
  GuestSubmissionIndexRoute: GuestSubmissionIndexRoute,
}

const GuestRouteWithChildren = GuestRoute._addFileChildren(GuestRouteChildren)

interface ProtectedRouteChildren {
  ProtectedDashboardIndexRoute: typeof ProtectedDashboardIndexRoute
  ProtectedSubcontractorsIndexRoute: typeof ProtectedSubcontractorsIndexRoute
  ProtectedTendersIndexRoute: typeof ProtectedTendersIndexRoute
  ProtectedSubcontractorsSubcontractorIdIndexRoute: typeof ProtectedSubcontractorsSubcontractorIdIndexRoute
  ProtectedSubcontractorsCreateIndexRoute: typeof ProtectedSubcontractorsCreateIndexRoute
  ProtectedSubcontractorsImportIndexRoute: typeof ProtectedSubcontractorsImportIndexRoute
  ProtectedTendersTenderIdIndexRoute: typeof ProtectedTendersTenderIdIndexRoute
  ProtectedTendersCreateIndexRoute: typeof ProtectedTendersCreateIndexRoute
  ProtectedTendersTenderIdPackagesPackageIdIndexRoute: typeof ProtectedTendersTenderIdPackagesPackageIdIndexRoute
}

const ProtectedRouteChildren: ProtectedRouteChildren = {
  ProtectedDashboardIndexRoute: ProtectedDashboardIndexRoute,
  ProtectedSubcontractorsIndexRoute: ProtectedSubcontractorsIndexRoute,
  ProtectedTendersIndexRoute: ProtectedTendersIndexRoute,
  ProtectedSubcontractorsSubcontractorIdIndexRoute:
    ProtectedSubcontractorsSubcontractorIdIndexRoute,
  ProtectedSubcontractorsCreateIndexRoute:
    ProtectedSubcontractorsCreateIndexRoute,
  ProtectedSubcontractorsImportIndexRoute:
    ProtectedSubcontractorsImportIndexRoute,
  ProtectedTendersTenderIdIndexRoute: ProtectedTendersTenderIdIndexRoute,
  ProtectedTendersCreateIndexRoute: ProtectedTendersCreateIndexRoute,
  ProtectedTendersTenderIdPackagesPackageIdIndexRoute:
    ProtectedTendersTenderIdPackagesPackageIdIndexRoute,
}

const ProtectedRouteWithChildren = ProtectedRoute._addFileChildren(
  ProtectedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof ProtectedRouteWithChildren
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/password-reset': typeof AuthPasswordResetRoute
  '/submission/accepted': typeof GuestSubmissionAcceptedRoute
  '/submission/action': typeof GuestSubmissionActionRoute
  '/submission/declined': typeof GuestSubmissionDeclinedRoute
  '/submission/expired': typeof GuestSubmissionExpiredRoute
  '/submission/submitted': typeof GuestSubmissionSubmittedRoute
  '/submission': typeof GuestSubmissionIndexRoute
  '/dashboard': typeof ProtectedDashboardIndexRoute
  '/subcontractors': typeof ProtectedSubcontractorsIndexRoute
  '/tenders': typeof ProtectedTendersIndexRoute
  '/subcontractors/$subcontractorId': typeof ProtectedSubcontractorsSubcontractorIdIndexRoute
  '/subcontractors/create': typeof ProtectedSubcontractorsCreateIndexRoute
  '/subcontractors/import': typeof ProtectedSubcontractorsImportIndexRoute
  '/tenders/$tenderId': typeof ProtectedTendersTenderIdIndexRoute
  '/tenders/create': typeof ProtectedTendersCreateIndexRoute
  '/tenders/$tenderId/packages/$packageId': typeof ProtectedTendersTenderIdPackagesPackageIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof ProtectedRouteWithChildren
  '/forgot-password': typeof AuthForgotPasswordRoute
  '/login': typeof AuthLoginRoute
  '/password-reset': typeof AuthPasswordResetRoute
  '/submission/accepted': typeof GuestSubmissionAcceptedRoute
  '/submission/action': typeof GuestSubmissionActionRoute
  '/submission/declined': typeof GuestSubmissionDeclinedRoute
  '/submission/expired': typeof GuestSubmissionExpiredRoute
  '/submission/submitted': typeof GuestSubmissionSubmittedRoute
  '/submission': typeof GuestSubmissionIndexRoute
  '/dashboard': typeof ProtectedDashboardIndexRoute
  '/subcontractors': typeof ProtectedSubcontractorsIndexRoute
  '/tenders': typeof ProtectedTendersIndexRoute
  '/subcontractors/$subcontractorId': typeof ProtectedSubcontractorsSubcontractorIdIndexRoute
  '/subcontractors/create': typeof ProtectedSubcontractorsCreateIndexRoute
  '/subcontractors/import': typeof ProtectedSubcontractorsImportIndexRoute
  '/tenders/$tenderId': typeof ProtectedTendersTenderIdIndexRoute
  '/tenders/create': typeof ProtectedTendersCreateIndexRoute
  '/tenders/$tenderId/packages/$packageId': typeof ProtectedTendersTenderIdPackagesPackageIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_guest': typeof GuestRouteWithChildren
  '/_protected': typeof ProtectedRouteWithChildren
  '/_auth/forgot-password': typeof AuthForgotPasswordRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/password-reset': typeof AuthPasswordResetRoute
  '/_guest/submission/accepted': typeof GuestSubmissionAcceptedRoute
  '/_guest/submission/action': typeof GuestSubmissionActionRoute
  '/_guest/submission/declined': typeof GuestSubmissionDeclinedRoute
  '/_guest/submission/expired': typeof GuestSubmissionExpiredRoute
  '/_guest/submission/submitted': typeof GuestSubmissionSubmittedRoute
  '/_guest/submission/': typeof GuestSubmissionIndexRoute
  '/_protected/dashboard/': typeof ProtectedDashboardIndexRoute
  '/_protected/subcontractors/': typeof ProtectedSubcontractorsIndexRoute
  '/_protected/tenders/': typeof ProtectedTendersIndexRoute
  '/_protected/subcontractors/$subcontractorId/': typeof ProtectedSubcontractorsSubcontractorIdIndexRoute
  '/_protected/subcontractors/create/': typeof ProtectedSubcontractorsCreateIndexRoute
  '/_protected/subcontractors/import/': typeof ProtectedSubcontractorsImportIndexRoute
  '/_protected/tenders/$tenderId/': typeof ProtectedTendersTenderIdIndexRoute
  '/_protected/tenders/create/': typeof ProtectedTendersCreateIndexRoute
  '/_protected/tenders/$tenderId/packages/$packageId/': typeof ProtectedTendersTenderIdPackagesPackageIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/forgot-password'
    | '/login'
    | '/password-reset'
    | '/submission/accepted'
    | '/submission/action'
    | '/submission/declined'
    | '/submission/expired'
    | '/submission/submitted'
    | '/submission'
    | '/dashboard'
    | '/subcontractors'
    | '/tenders'
    | '/subcontractors/$subcontractorId'
    | '/subcontractors/create'
    | '/subcontractors/import'
    | '/tenders/$tenderId'
    | '/tenders/create'
    | '/tenders/$tenderId/packages/$packageId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/forgot-password'
    | '/login'
    | '/password-reset'
    | '/submission/accepted'
    | '/submission/action'
    | '/submission/declined'
    | '/submission/expired'
    | '/submission/submitted'
    | '/submission'
    | '/dashboard'
    | '/subcontractors'
    | '/tenders'
    | '/subcontractors/$subcontractorId'
    | '/subcontractors/create'
    | '/subcontractors/import'
    | '/tenders/$tenderId'
    | '/tenders/create'
    | '/tenders/$tenderId/packages/$packageId'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/_guest'
    | '/_protected'
    | '/_auth/forgot-password'
    | '/_auth/login'
    | '/_auth/password-reset'
    | '/_guest/submission/accepted'
    | '/_guest/submission/action'
    | '/_guest/submission/declined'
    | '/_guest/submission/expired'
    | '/_guest/submission/submitted'
    | '/_guest/submission/'
    | '/_protected/dashboard/'
    | '/_protected/subcontractors/'
    | '/_protected/tenders/'
    | '/_protected/subcontractors/$subcontractorId/'
    | '/_protected/subcontractors/create/'
    | '/_protected/subcontractors/import/'
    | '/_protected/tenders/$tenderId/'
    | '/_protected/tenders/create/'
    | '/_protected/tenders/$tenderId/packages/$packageId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  GuestRoute: typeof GuestRouteWithChildren
  ProtectedRoute: typeof ProtectedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  GuestRoute: GuestRouteWithChildren,
  ProtectedRoute: ProtectedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_auth",
        "/_guest",
        "/_protected"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/forgot-password",
        "/_auth/login",
        "/_auth/password-reset"
      ]
    },
    "/_guest": {
      "filePath": "_guest.tsx",
      "children": [
        "/_guest/submission/accepted",
        "/_guest/submission/action",
        "/_guest/submission/declined",
        "/_guest/submission/expired",
        "/_guest/submission/submitted",
        "/_guest/submission/"
      ]
    },
    "/_protected": {
      "filePath": "_protected.tsx",
      "children": [
        "/_protected/dashboard/",
        "/_protected/subcontractors/",
        "/_protected/tenders/",
        "/_protected/subcontractors/$subcontractorId/",
        "/_protected/subcontractors/create/",
        "/_protected/subcontractors/import/",
        "/_protected/tenders/$tenderId/",
        "/_protected/tenders/create/",
        "/_protected/tenders/$tenderId/packages/$packageId/"
      ]
    },
    "/_auth/forgot-password": {
      "filePath": "_auth/forgot-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/password-reset": {
      "filePath": "_auth/password-reset.tsx",
      "parent": "/_auth"
    },
    "/_guest/submission/accepted": {
      "filePath": "_guest/submission/accepted.tsx",
      "parent": "/_guest"
    },
    "/_guest/submission/action": {
      "filePath": "_guest/submission/action.tsx",
      "parent": "/_guest"
    },
    "/_guest/submission/declined": {
      "filePath": "_guest/submission/declined.tsx",
      "parent": "/_guest"
    },
    "/_guest/submission/expired": {
      "filePath": "_guest/submission/expired.tsx",
      "parent": "/_guest"
    },
    "/_guest/submission/submitted": {
      "filePath": "_guest/submission/submitted.tsx",
      "parent": "/_guest"
    },
    "/_guest/submission/": {
      "filePath": "_guest/submission/index.tsx",
      "parent": "/_guest"
    },
    "/_protected/dashboard/": {
      "filePath": "_protected/dashboard/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/subcontractors/": {
      "filePath": "_protected/subcontractors/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/tenders/": {
      "filePath": "_protected/tenders/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/subcontractors/$subcontractorId/": {
      "filePath": "_protected/subcontractors/$subcontractorId/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/subcontractors/create/": {
      "filePath": "_protected/subcontractors/create/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/subcontractors/import/": {
      "filePath": "_protected/subcontractors/import/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/tenders/$tenderId/": {
      "filePath": "_protected/tenders/$tenderId/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/tenders/create/": {
      "filePath": "_protected/tenders/create/index.tsx",
      "parent": "/_protected"
    },
    "/_protected/tenders/$tenderId/packages/$packageId/": {
      "filePath": "_protected/tenders/$tenderId/packages/$packageId/index.tsx",
      "parent": "/_protected"
    }
  }
}
ROUTE_MANIFEST_END */

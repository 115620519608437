export const API_URL = import.meta.env.VITE_API_URL;
export const APP_URL = import.meta.env.VITE_APP_URL;
export const LANDING_URL = import.meta.env.VITE_LANDING_URL;

export const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

export const version = '1.0.0';
export const mailFeedback = 'feedback@decocorp-rfq.com';
export const mailAdmin = 'info@decocorp-rfq.com';
export const termsUrl = 'https://decocorp-rfq.com/terms';
export const privacyUrl = 'https://decocorp-rfq.com/privacy';

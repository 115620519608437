import clsx from 'clsx';
import styles from './loader.module.css';

type LoaderProps = {
  size?: 'small' | 'normal';
};

export function Loader(props: LoaderProps) {
  const { size = 'normal' } = props;
  return (
    <div
      className={clsx(styles.root, { [styles.sizeSmall]: size === 'small', [styles.sizeNormal]: size === 'normal' })}
    />
  );
}

import { Toaster } from 'react-hot-toast';
import { Outlet, createRootRoute } from '@tanstack/react-router';

import { Flasher } from '@/utilities/flash';

import '@/styles/preflight.css';
import '@/styles/global.css';

import styles from './__root.module.css';

export const Route = createRootRoute({
  component: RootComponent,
});

function RootComponent() {
  return (
    <div className={styles.root}>
      <Outlet />
      <Toaster />
      <Flasher />
    </div>
  );
}

export const getWelcomePath = () => {
  return `/dashboard`;
};

export const getSupportPath = () => {
  return `/support`;
};

export const get404Path = () => {
  return `/404`;
};

export const unpackRedirectPath = (redirect: string) => {
  return `/${decodeURIComponent(redirect)}`.replace('//', '/');
};

export const getLoginPath = (redirect?: string | null) => {
  return `/login` + (redirect ? `?redirect=${redirect}` : '');
};

export const getResetPath = () => {
  return `/password/reset`;
};

export const getBillingPath = () => {
  return `/billing`;
};

export const getTendersIndexPath = () => {
  return `/tenders`;
};

export const getCreateTenderPath = () => {
  return `/tenders/create`;
};

export const getEditTenderPath = (id: string) => {
  return `/tenders/${id}`;
};

export const getEditPackagePath = (tenderId: string, packageId: string) => {
  return `/tenders/${tenderId}/packages/${packageId}`;
};

export const getSubcontractorsIndexPath = () => {
  return `/subcontractors`;
};

export const getCreateSubcontractorPath = () => {
  return `/subcontractors/create`;
};

export const getImportSubcontractorPath = () => {
  return `/subcontractors/import`;
};

export const getEditSubcontractorPath = (id: string) => {
  return `/subcontractors/${id}`;
};

export const getSubmissionPath = (packageId: string) => {
  return `/submission?packageId=${packageId}`;
};

// Utility functions
export const isGuestPath = (path: string) => {
  return path === getLoginPath() || path === getResetPath();
};

// URLSearchParams and Next' useSearchParams both attempt to decode the query string
export function getParam(key: string, defaultValue: any = undefined) {
  const params = new Map(
    location.search
      .slice(1)
      .split('&')
      .map((q) => q.split('=') as [string, string]),
  );

  return params.get(key) ?? defaultValue;
}

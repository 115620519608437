import { type KeyboardEvent, type ChangeEvent, forwardRef, type HTMLInputTypeAttribute, ReactNode } from 'react';
import clsx from 'clsx';

import { Loader } from '../loader/loader';

import style from './input.module.css';

type Props = {
  accept?: string;
  autoFocus?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  name?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onChange?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (value: string, e: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  size?: number;
  tabIndex?: number;
  testId?: string;
  type?: HTMLInputTypeAttribute;
  pattern?: string;
  value?: string;
  variant?: 'regular' | 'large';
  maxLength?: number;
};

const noop = () => {};

export const Input = forwardRef<HTMLInputElement, Props>(function Input(props, ref) {
  const {
    accept,
    autoFocus = false,
    disabled = false,
    isLoading = false,
    icon,
    name,
    tabIndex,
    onBlur = noop,
    onFocus = noop,
    onChange = noop,
    onKeyUp = noop,
    placeholder = '',
    readOnly = false,
    required,
    size = 1,
    type = 'text',
    testId,
    pattern,
    value,
    variant = 'regular',
    maxLength,
  } = props;

  const isFileInput = type === 'file';

  return (
    <div
      className={clsx(style.root, style[`type--${type}`], {
        [style.hasIcon]: icon,
        [style.isFileInput]: isFileInput,
        [style.isLoading]: isLoading,
        [style.large]: variant === 'large',
      })}
    >
      <input
        accept={accept}
        autoCapitalize="none"
        autoFocus={autoFocus}
        className={style.input}
        data-testid={testId}
        disabled={disabled || isLoading}
        name={name}
        onBlur={(e) => onBlur(e.currentTarget.value)}
        onFocus={(e) => onFocus(e.currentTarget.value)}
        onChange={(e) => onChange(e.currentTarget.value, e)}
        onKeyUp={(e) => onKeyUp(e.currentTarget.value, e)}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        size={size}
        tabIndex={tabIndex}
        type={type}
        value={value}
        maxLength={maxLength}
        pattern={pattern}
        required={required}
      />
      {icon ? <span className={style.icon}>{icon}</span> : null}
      {isFileInput ? (
        <div className={style.uploading}>
          <span>Uploading...</span>
          <Loader size="small" />
        </div>
      ) : null}
    </div>
  );
});

import { type ReactNode } from 'react';
import clsx from 'clsx';

import styles from './card.module.css';

type CardProps = {
  children?: ReactNode;
  withPadding?: boolean;
  canHover?: boolean;
};

type BottomBarProps = {
  children?: ReactNode;
};

export function BottomBar(props: BottomBarProps) {
  const { children } = props;

  return <div className={styles.bottomBar}>{children}</div>;
}

export function Card(props: CardProps) {
  const { children, withPadding = true, canHover = false } = props;

  return (
    <div className={clsx(styles.root, { [styles.withPadding]: withPadding, [styles.canHover]: canHover })}>
      {children}
    </div>
  );
}

import { ReactNode } from 'react';

import style from './form-error.module.css';

interface FormErrorProps {
  children?: ReactNode;
}

export function FormError(props: FormErrorProps) {
  const { children } = props;
  return <div className={style.root}>{children}</div>;
}

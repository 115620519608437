import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './label.module.css';

type LabelProps = {
  children?: ReactNode;
  slot?: ReactNode;
  infoSlot?: ReactNode;
  text: string;
  // For Switch
  fitContent?: boolean;
};

export function Label(props: LabelProps) {
  const { children, text, slot, infoSlot, fitContent = false } = props;

  return (
    <div
      className={clsx(styles.root, {
        [styles.fitContent]: fitContent,
      })}
    >
      <label className={styles.input}>
        <div className={styles.label}>
          <span className={styles.text}>{text}</span>
          {slot ? <span className={styles.slot}>{slot}</span> : null}
        </div>
        {children}
      </label>
      <div className={styles.info}>{infoSlot}</div>
    </div>
  );
}

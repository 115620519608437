import { useEffect, useState } from 'react';

import { ping } from '@/utilities/api';
import { redirectWithFlash } from '@/utilities/flash';
import { getWelcomePath } from '@/utilities/paths';
import { Route } from '@/routes/_auth';

export function useRedirectAuthenticatedUser() {
  const [checked, setChecked] = useState(false);
  const searchParams = Route.useSearch();

  useEffect(() => {
    (async () => {
      try {
        const isLoggedIn = await ping();
        if (isLoggedIn) {
          const { redirect } = searchParams;
          redirectWithFlash(redirect ? decodeURIComponent(redirect) : getWelcomePath(), 'Welcome back!', 'success');
        } else {
          setChecked(true);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_e) {
        // do nothing, they are most likely on a guest route and can continue even with an error to ping
      }
    })();
  }, [searchParams]);

  return checked;
}

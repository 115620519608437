import { type ReactNode, FormEvent } from 'react';

import { Card } from '../card/card';
import { Loader } from '../loader/loader';

import styles from './auth-card.module.css';

type AuthCardProps = {
  children?: ReactNode;
  crossLink?: ReactNode;
  onSubmit: (e: FormEvent) => void;
  title: string;
  isLoading: boolean;
};

export function AuthCard(props: AuthCardProps) {
  const { children, onSubmit, crossLink, title, isLoading } = props;

  return (
    <div className={styles.root}>
      <Card withPadding={false}>
        <div className={styles.container}>
          {isLoading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : null}
          <form className={styles.form} onSubmit={onSubmit}>
            <h1>{title}</h1>
            {children}
          </form>
        </div>
      </Card>
      <div className={styles.signupLink}>{crossLink}</div>
    </div>
  );
}
